<template>
  <v-row>
    <!-- <v-col cols="12" md="12">
      <app-stats></app-stats>
    </v-col> -->
    <v-col cols="12" md="12">
      <vue-inline-alert
        v-if="(showErrorMessage || showMessage) && !isLoaded"
        message-type="error"
        message="Failed uploading the release. We've reverted back the previous latest release if any. If it you are still experiencing the same issue, please contact us"
      />
      <v-row v-if="loader || isLoaded">
        <v-col cols="12" class="">
          <v-skeleton-loader width="100%" type="image, article" />
        </v-col>

        <!--        <v-col class="d-flex justify-center">-->
        <!--          <v-skeleton-loader width="370" type="image, article" />-->
        <!--        </v-col>-->
        <v-col cols="12" class="mt-12">
          <v-skeleton-loader type="divider" class="transparent" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader width="250" type="heading" class="transparent" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader
            type="table-thead, table-tbody"
            class="transparent"
          />
        </v-col>
      </v-row>
      <v-card
        v-else-if="(!noReleases || !noLatestReleases) && !noInfo"
        outlined
        class="transparent mt-n5"
      >
        <v-row align="center" class="mt-1">
          <v-col cols="6" class="font-size-h3"> Latest release </v-col>
          <v-col cols="6" class="text-right">
            <new-release-panel
              v-if="canManageRelease"
              :app-info="appInfo"
              style="float: right"
              :click="showNewRelease"
              @socketReconnect="reconnectToSocket"
              @close="showNewRelease = false"
              @releaseSuccess="$emit('showInstruction')"
              @created="showInfo($event)"
            />
          </v-col>
        </v-row>
        <div class="release-body">
          <v-row v-if="!noLatestReleases">
            <v-col cols="12">
              <latest-release-card
                :releases="latest_releases"
                :app="info"
                @onEdit="onEditClick"
                @onDelete="onDeleteClick"
                @onDownload="onDownloadClick"
                @onClick="openReleaseInfo"
                @install="$emit('install', $event)"
                @move-release="openMoveReleaseDialog"
                @viewInstallLog="openInstallList"
                @viewReleaseFeedback="openReleaseFeedback"
                @onArchive="onArchivedClicked($event)"
              ></latest-release-card>
            </v-col>
          </v-row>
          <div v-else class="d-flex justify-center align-center mt-15 px-6">
            <div>
              <inline-svg
                class="mt-6 svg-icon empty_icon"
                src="/media/placeholders/no_apps.svg"
              />
              <p class="mt-4 empty-title bold-text text-dark text-center">
                No active releases
              </p>
              <p
                class="
                  mt-4
                  mb-5
                  empty-sub-title
                  font-weight-normal
                  text-dark text-center
                "
              ></p>
            </div>
          </div>
          <v-divider class="my-5" />

          <!-- v-data-table -->
          <div>
            <v-card-title class="px-0 font-size-h3">
              Previous
              <v-spacer />
              <v-menu
                bottom
                left
                offset-y
                v-model="menu"
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text-transform-none cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Release status
                    <v-icon right v-text="'mdi-help-circle'"></v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <div class="font-size-h3 bold-text">Upload Status</div>
                  </v-card-title>
                  <v-card-text>
                    <v-row
                      v-for="(item, index) in $options.STATUSDESC"
                      :key="index"
                    >
                      <v-col cols="12">
                        <div>
                          <v-icon
                            v-text="'mdi-radiobox-blank'"
                            small
                            :color="item.color"
                          ></v-icon>
                          <span class="bold-text">
                            {{ item.title }}
                          </span>
                        </div>
                        <div v-html="item.desc" class="mx-5"></div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-col v-if="previousDataLoader" cols="12">
              <v-skeleton-loader
                type="table-thead, table-tbody"
                class="transparent"
              />
            </v-col>
            <v-data-table
              v-else
              :headers="headers"
              :items="releases"
              :search="search"
              :page.sync="page"
              :expanded.sync="expanded"
              :server-items-length="totalItems"
              class="
                v-card v-sheet v-sheet--outlined
                hide-v-data-footer__select
                radius-10
                release-table
              "
              @click:row="expendRow"
              mobile-breakpoint="1070"
            >
              <!-- <td> -> icons -->
              <template #item.icon="{ item }">
                <v-badge avatar color="white" overlap>
                  <template slot="badge">
                    <v-avatar color="white" size="25">
                      <v-icon
                        color="primary"
                        small
                        v-text="
                          item.platform == 'android'
                            ? 'mdi-android'
                            : 'mdi-apple'
                        "
                      ></v-icon>
                    </v-avatar>
                  </template>
                  <div>
                    <img
                      height="40"
                      :src="item.image || '/media/defaults/icon-50.png'"
                      @error="replaceDefaultReleaseImage"
                    />
                  </div>
                </v-badge>
                <div class="d-flex justify-center align-center"></div>
              </template>

              <!-- <td> -> versions -->
              <template #item.version="{ item }">
                <MaxText
                  :text="`v${item.info.version} (${item.info.version_code})`"
                  max="30"
                />
              </template>

              <!-- <td> -> sizes -->
              <template #item.size="{ item }">
                {{ formatBytes(item.info.size) }}
              </template>

              <!-- <td> -> developers -->
              <template #item.developer="{ item }">
                {{ item.user.name }}
              </template>
              <template #item.download="{ item }">
                <span v-if="canManageApp">
                  <div @click.stop="openInstallList(item)">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn v-on="on" icon color="primary">
                          <b>{{
                            get(item, "meta.total_downloads", 0) +
                            get(item, "meta.total_installs", 0)
                          }}</b>
                        </v-btn>
                      </template>
                      <span>
                        <div>
                          Total downloads:
                          {{ get(item, "meta.total_downloads", 0) }}
                        </div>
                        <div>
                          Total install:
                          {{ get(item, "meta.total_installs", 0) }}
                        </div>
                        <div class="my-3">
                          We are updating this info every minute.
                        </div>
                      </span>
                    </v-tooltip>
                  </div>
                </span>
                <span v-else> - </span>
              </template>
              <template #item.feedback="{ item }">
                <span v-if="canManageApp">
                  <v-btn
                    @click.stop="openReleaseFeedback(item)"
                    icon
                    color="primary"
                  >
                    <b>{{ get(item, "meta.total_feedback", 0) }}</b>
                  </v-btn>
                </span>
                <span v-else> - </span>
              </template>
              <!-- <td> -> dates -->
              <template #item.archive="{ item }">
                <div v-if="item.archive_at">
                  <vue-hoverable-date
                    v-if="item.archive_at"
                    :date="item.archive_at"
                  ></vue-hoverable-date>
                </div>
                <div v-else>
                  <vue-tooltip
                    text="No expiry date"
                    displayText="-"
                  ></vue-tooltip>
                </div>
              </template>
              <template #item.date="{ item }">
                <vue-hoverable-date
                  :date="item.created_at"
                ></vue-hoverable-date>
              </template>
              <template #item.status="{ item }">
                <div>
                  <vue-tooltip
                    :icon-color="$options.STATUS[item.status].color"
                    icon-text="mdi-radiobox-blank"
                    :text="$options.STATUS[item.status].title"
                  >
                  </vue-tooltip>
                </div>
              </template>

              <!-- <td> -> drop down -->
              <template #item.action="{ item }">
                <action-dropdown>
                  <template v-slot:list>
                    <!-- item in the drop down -->
                    <v-list-item
                      v-if="canManageRelease && ![2, 3].includes(item.status)"
                      @click="onEditClick(item)"
                      ><v-icon small>edit</v-icon>Edit Note
                    </v-list-item>
                    <!--  -->
                    <!-- item in the drop down -->
                    <!--                    <v-list-item-->
                    <!--                      v-if="[1].includes(item.status)"-->
                    <!--                      @click="$emit('install', item)"-->
                    <!--                      ><v-icon small>mdi-cellphone-iphone</v-icon>install-->
                    <!--                    </v-list-item>-->
                    <v-list-item
                      v-if="canManageRelease && item.status === 1"
                      @click="openMoveReleaseDialog(item)"
                      ><v-icon small>mdi-cursor-move</v-icon>Move
                      <!-- <v-icon
                        v-if="!isPaidUser"
                        small
                        left
                        class="mx-2"
                        v-text="'mdi-lock'"
                      ></v-icon> -->
                    </v-list-item>

                    <v-list-item
                      v-if="canManageRelease && [2, 3].includes(item.status)"
                      @click="onDeleteClick(item)"
                      ><v-icon small>cancel</v-icon>Delete
                    </v-list-item>
                    <v-list-item
                      v-if="canManageRelease && [1].includes(item.status)"
                      @click="onArchivedClicked(item, 'fromList')"
                      ><v-icon small>archive</v-icon>Archive
                    </v-list-item>
                    <v-list-item
                      v-if="[1].includes(item.status)"
                      @click="onDownloadClick(item)"
                      ><v-icon small>get_app</v-icon>Download app file
                    </v-list-item>

                    <!--  -->
                  </template>
                </action-dropdown>
              </template>
              <!-- if no data -->
              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0">
                  <v-card tile flat>
                    <v-card-text class="pb-4">
                      <v-row>
                        <v-col class="pb-0" cols="6">
                          <div class="mb-3">
                            <v-icon
                              v-text="'mdi-chevron-right'"
                              size="20"
                            ></v-icon>
                            <b>Release ID:</b>

                            {{ item.id }}
                          </div>
                          <div>
                            <v-icon
                              v-text="'mdi-chevron-right'"
                              size="20"
                            ></v-icon>
                            <b>Package Name: </b>
                            {{ item.info.package }}
                          </div>
                        </v-col>
                        <v-col class="pb-0" cols="6">
                          <v-icon
                            v-text="'mdi-note-outline'"
                            size="20"
                            left
                          ></v-icon>
                          <b>Release Notes: </b>
                          <div
                            class="mx-5"
                            style="white-space: pre-wrap"
                            v-if="item.note"
                          >
                            <div v-if="item && item.note.length > 175">
                              {{
                                readMore
                                  ? item.note.substring(0, 175)
                                  : item.note
                              }}
                              <span
                                style="cursor: pointer"
                                class="primary--text font-weight-bold"
                                @click="readMore = !readMore"
                                >{{
                                  readMore ? "... Read More" : "Read Less"
                                }}</span
                              >
                            </div>
                            <span v-else>
                              {{ item.note }}
                            </span>
                          </div>
                          <span v-else>-</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </td>
              </template>
              <template #no-data>
                <no-data
                  icon="/media/placeholders/no_devices.svg"
                  first-text="No previous releases, just yet..."
                  second-text="Once you upload a new release, the latest release will move
                  here and it can be still available for install if not yet
                  archived."
                ></no-data>
              </template>
            </v-data-table>
          </div>
          <!-- /v-data-table -->
        </div>
        <Modal v-model="modal">
          <template #message>
            <v-card-title class="font-weight-bold">Delete release</v-card-title>
            <v-divider />
            <v-card-text class="message">
              <p>Are you sure you want to delete this release?</p>
              <v-alert
                class="mt-3"
                border="left"
                color="info"
                icon="info"
                outlined
              >
                Note: Deleting this release will be removed from your app and
                team's stats.
                <br /><br />

                Release already archived and removed from the storage cost as
                well as the ability to be installed or the visibility in the our
                mobile app.

                <br /><br />It's just a record for your reference.
              </v-alert>
            </v-card-text>
            <v-divider />
            <v-card-actions class="pb-0">
              <v-spacer />
              <v-btn class="main" @click="onCancel">no</v-btn>
              <v-btn class="main primary" @click="onConfirm" :loading="loading"
                >yes</v-btn
              >
            </v-card-actions>
          </template>
        </Modal>
        <Modal v-model="archiveModal" warning>
          <template #message>
            <template v-if="archiveModal">
              <div class="my-n3">
                <v-card-title class="font-weight-bold"
                  >Archive release</v-card-title
                >
                <v-divider />
                <v-card-text class="message">
                  <p>Are you sure you want to archive this release?</p>

                  <ul class="dark-grey-text">
                    <li>Platform: {{ selectedRelease.platform }}</li>
                    <li>
                      Version:
                      <MaxText
                        :text="`v${selectedRelease.info.version} (${selectedRelease.info.version_code})`"
                        max="40"
                      />
                    </li>
                  </ul>

                  <v-alert
                    class="mt-3"
                    border="left"
                    color="info"
                    icon="info"
                    outlined
                  >
                    Archiving this release will make it unavailable for install
                    and will be removed from the storage cost.
                  </v-alert>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn class="main mr-2" @click="onCancelArchive">no</v-btn>
                  <v-btn
                    class="main primary"
                    @click="onConfirmArchive"
                    :loading="loading"
                    >yes</v-btn
                  >
                </v-card-actions>
              </div>
            </template>
          </template>
        </Modal>
      </v-card>
      <noData v-else-if="noInfo" :firstText="errorMessage" permission-issues />
      <template v-else-if="noReleases && canManageRelease">
        <div>
          <new-release-panel
            v-if="canManageRelease"
            :app-info="appInfo"
            style="float: right"
            :click="showNewRelease"
            @socketReconnect="reconnectToSocket"
            @close="showNewRelease = false"
            @releaseSuccess="$emit('showInstruction')"
            @created="showInfo($event)"
          />
        </div>
        <div>
          <newRelease
            v-if="noReleases && canManageRelease"
            @openNewRelease="showNewRelease = true"
            :app-info="appInfo"
          />
        </div>
      </template>

      <div
        v-else-if="noReleases && !canManageRelease"
        class="no-data-found mt-6"
      >
        <no-data
          icon="/media/placeholders/no_releases.svg"
          first-text="No releases"
          second-text="Releases will be installed from our mobile app"
        ></no-data>
      </div>
      <!--  -->
      <!-- use the SidePanel component instead of the navigation drawer -->
      <SidePanel v-model="viewEditRelease">
        <!--  -->
        <UpdateReleasePanel
          :app_id="app_id"
          :release="selectedRelease"
          :onClose="() => (viewEditRelease = false)"
        />
        <!--  -->
      </SidePanel>
      <!--  -->
      <SidePanel width="900" v-model="showReleaseInstallList" hideOverlay>
        <install-list
          :app_id="app_id"
          from-release-page
          :app="info"
          :release="selectedRelease"
        ></install-list>
      </SidePanel>
      <SidePanel width="900" v-model="showReleaseFeedback" hideOverlay>
        <release-feedback
          :app_id="app_id"
          :app="info"
          :release="selectedRelease"
        ></release-feedback>
      </SidePanel>
      <modal v-model="moveRelease" @close="moveRelease = false">
        <template #message>
          <move-release
            v-if="moveRelease"
            :release="selectedRelease"
            :current-app="info"
            @close="refreshDetails"
          >
          </move-release>
        </template>
      </modal>
      <!-- use the SidePanel component instead of the navigation drawer -->
      <SidePanel v-model="viewRleaseInfo" hideOverlay width="600">
        <!--  -->
        <ReleaseInfoPanel
          :release="selectedRelease"
          :app_id="app_id"
          :app="info"
          @installModal="$emit('install', $event)"
          :onClose="() => (viewRleaseInfo = false)"
        />
        <!--  -->
      </SidePanel>
      <Modal v-model="showUpgradeInfo" @close="showUpgradeInfo = false">
        <template #message>
          <upgrade-plan
            title="Move release"
            info-text="move your release"
            @close="showUpgradeInfo = false"
          >
            <template>
              <div>
                You can move your release to other apps. <br /><br />

                <br />If you have same app from different environment like
                (Production, Local, Development) you can move your release to
                those app.!
              </div>
            </template>
          </upgrade-plan>
        </template>
      </Modal>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import { isEmpty, formatBytes, get } from "@/core/services/helper.service";
import { mapState, mapGetters, mapMutations } from "vuex";
import MoveRelease from "@/view/components/Releases/MoveRelease.vue";
import {
  RELEASES,
  DELETE_RELEASE,
  ARCHIVE_RELEASE,
} from "@/store/releases/releases.module.js";
import ReleaseInfoPanel from "@/view/components/Releases/ReleaseInfoPanel.vue";
import UpdateReleasePanel from "@/view/components/Releases/UpdateReleasePanel.vue";
import newRelease from "@/view/components/Releases/NewRelease";
import NewReleasePanel from "@/view/components/Releases/NewReleasePanel.vue";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import { INFO, CLEAR_APP } from "@/store/apps/app.module";
import InstallList from "@/view/components/App/Dashboard/InstallList.vue";
import UpgradePlan from "@/view/components/Team/UpgradePlan.vue";
import ReleaseFeedback from "@/view/components/App/ReleaseFeedback";
import LatestReleaseCard from "@/view/components/Releases/LatestReleaseCard";

export default {
  name: "app-releases",
  // these loader is for app info
  props: {
    infoError: {
      type: String,
      default: null,
    },
  },

  components: {
    LatestReleaseCard,
    ReleaseFeedback,
    ActionDropdown,
    NewReleasePanel,
    MoveRelease,
    UpdateReleasePanel,
    ReleaseInfoPanel,
    UpgradePlan,
    InstallList,
    newRelease,
  },
  STATUS: {
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "primary" },
  },
  STATUSDESC: [
    {
      title: "Active",
      color: "green",
      desc: "Release is active and ready to be installed",
    },
    {
      title: "Failed",
      color: "red",
      desc: "Upload failed. The release will be automatically deleted",
    },
    {
      title: "Archived",
      color: "grey",
      desc: "Release archived. Will not be available for install or download and the size will be removed from the team storage",
    },
    {
      title: "Uploading",
      color: "orange",
      desc: "Release file is being uploaded to our server. <br /> If you refreshed your page or connection lost, select the same file again and it will resume the upload in a new release",
    },
    {
      title: "Processing",
      color: "primary",
      desc: "Release file is successfully uploaded and being processed to be available for install and download",
    },
  ],
  data() {
    return {
      // table hader
      options: {},
      moveRelease: false,
      previousDataLoader: false,
      showUpgradeInfo: false,
      expanded: [],
      menu: false,
      breadCrumbs: [
        { text: "Apps", disabled: false, to: { name: "apps" } },
        {
          text: "...",
          disabled: false,
          to: {
            name: "app-dashboard",
            params: {
              app_id: this.$route.params.app_id,
            },
          },
        },
        {
          text: "Releases",
          disabled: true,
          to: { name: "app-dashboard" },
        },
      ],
      readMore: true,
      showReleaseInstallList: false,
      showReleaseFeedback: false,
      headers: [
        {
          text: "Icon",
          align: "center",
          sortable: false,
          width: "6%",
          value: "icon",
        },
        {
          text: "Version",
          align: "center",
          sortable: false,
          width: "12%",
          value: "version",
        },
        {
          text: "Size",
          align: "center",
          sortable: false,
          width: "15%",
          value: "size",
        },
        {
          text: "Developer",
          align: "center",
          sortable: false,
          width: "25%",
          value: "developer",
        },
        {
          text: "Installs",
          align: "center",
          sortable: false,
          width: "5%",
          value: "download",
        },
        {
          text: "Feedback",
          align: "center",
          sortable: false,
          width: "5%",
          value: "feedback",
        },
        {
          text: "Archive",
          align: "center",
          sortable: false,
          width: "20%",
          value: "archive",
        },
        {
          text: "Created",
          align: "center",
          sortable: false,
          width: "20%",
          value: "date",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          width: "4%",
          value: "status",
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          width: "3%",
          value: "action",
        },
      ],
      search: "",
      //
      get,
      page: 1,
      isLoaded: false,
      formatBytes,
      selectedRelease: null,
      message: "",
      loading: false,
      loader: false,
      alert: false,
      archiveModal: false,
      errorMessage: null,
      fromList: false,
      viewEditRelease: false,
      showNewRelease: false,
      viewRleaseInfo: false,
      modal: null,
      showErrorMessage: false,
      stillWaitingForData: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      info: (state) => state.app.info,
      releases: (state) => state.releases.releases,
      latest_releases: (state) => state.releases.latest_releases,
    }),
    ...mapGetters({
      releaseMessage: "getReleaseMessage",
      releaseMeta: "getReleasesMeta",
      getReleaseImage: "getReleaseImage",
      totalReleaseData: "getTotalReleasesData",
      appInfo: "getAppInfo",
      isSocketDisconnected: "getIsSocketDisconnected",
      getTeamId: "getTeamId",
      getDataFromSocket: "getDataFromSocket",
      getTeamPlanUsage: "getTeamPlanUsage",
    }),
    // isPaidUser: {
    //   get: function () {
    //     return this.getTeamPlanUsage && this.getTeamPlanUsage.integrations;
    //   },
    //   set: function () {},
    // },
    totalItems() {
      return this.releaseMeta.total - this.latest_releases.length;
    },
    app_id() {
      return this.$route.params.app_id;
    },
    noLatestReleases() {
      return isEmpty(this.latest_releases) ? true : false;
    },
    noReleases() {
      return isEmpty(this.releases) ? true : false;
    },
    noInfo() {
      return isEmpty(this.info) ? true : false;
    },
    showMessage() {
      return this.latest_releases.filter((x) => x.status === 2).length
        ? true
        : false;
    },
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalReleaseData.length > oldVal * 10) {
          this.setPreviousRelease(this.totalReleaseData.slice(offset, limit));
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.releases[this.releases.length - 1].id;
          this.loadData(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousRelease(this.totalReleaseData.slice(offset, limit));
      }
    },
    getDataFromSocket(val) {
      if (val) {
        if (val.app_id == this.app_id) {
          this.setReleaseData(val);
        }
      }
    },
    "$route.params.app_id"() {
      this.fetchInitialData();
    },
    latest_releases(val) {
      this.changePlatformAsArchived(
        val && val.length ? val.map((x) => x.platform) : []
      );
      if (val.status === 1) {
        this.setReleaseMessage("");
      }
    },
  },
  created() {
    if (isEmpty(this.appInfo)) {
      this.fetchInitialData();
    } else {
      this.loadData();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!isEmpty(this.appInfo)) {
        this.breadCrumbs[1].text = this.appInfo.name;
      }
    });
  },
  methods: {
    ...mapMutations({
      setReleaseMessage: "setReleaseMessage",
      removeReleaseInfo: "removeReleaseInfo",
      setAndroidLatestInstallData: "setAndroidLatestInstallData",
      setIosLatestInstallData: "setIosLatestInstallData",
      removeAppStats: "removeAppStats",
      setPreviousRelease: "setPreviousRelease",
      removeDeletedRelease: "removeDeletedRelease",
      changeReleaseToArchive: "changeReleaseToArchive",
      changePlatformAsArchived: "changePlatformAsArchived",
      removeReleaseImage: "removeReleaseImage",
      setLatestRelease: "setLatestRelease",
      setDataFromSocket: "setDataFromSocket",
      setLatestAndroidFeedback: "setLatestAndroidFeedback",
      setLatestIosFeedback: "setLatestIosFeedback",
    }),
    reconnectToSocket() {
      if (this.isSocketDisconnected) {
        this.connectToSocket();
      }
    },
    openMoveReleaseDialog(detail) {
      this.selectedRelease = detail;
      this.moveRelease = true;
      // if (this.isPaidUser) {

      // } else {
      //   this.showUpgradeInfo = true;
      // }
    },
    refreshDetails() {
      this.moveRelease = false;
      this.fetchInitialData();
    },
    fetchInitialData() {
      this.errorMessages = null;
      this.$store.dispatch(CLEAR_APP);
      if (this.noInfo) {
        this.loader = true;
        this.$store
          .dispatch(INFO, this.app_id)
          .then(() => {
            this.loader = false;
            if (!isEmpty(this.appInfo)) {
              this.breadCrumbs[1].text = this.appInfo.name;
            }
            this.loadData();
            this.errorMessages = null;
          })
          .catch((err) => {
            this.loader = false;
            this.$emit("info-error", err.message);
            this.errorMessages = err.message || null;
          });
      }
    },
    updateBreadCrumbs(detail) {
      this.breadCrumbs[1].text = detail.name;
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      }
    },
    showInstallModal() {
      const popup = JSON.parse(localStorage.getItem("popup"));
      let box = popup ? popup.releases : null;
      if (!box) {
        localStorage.setItem(
          "popup",
          JSON.stringify({
            ...popup,
            install: "false",
          })
        );
        box = popup;
      }
      if (box && box.release !== "true") {
        this.$emit("showInstruction");
      }
    },
    setReleaseData(data) {
      if (data.status === 2) {
        this.loadData(0, data.app_id);
        this.showErrorMessage = true;
      } else {
        let latestReleases = this.latest_releases.map((release) => {
          if (release && release.id === data.id) {
            release.status = data.status;
            release.url = data.url;
            release.archive_at = data.archive_at;
          }
          return release;
        });
        this.setLatestRelease(latestReleases);
        this.releases.map((release) => {
          if (release && release.id === data.id) {
            release.status = data.status;
            release.url = data.url;
            release.archive_at = data.archive_at;
          }
          return release;
        });
        this.totalReleaseData.map((release) => {
          if (release && release.id === data.id) {
            release.status = data.status;
            release.url = data.url;
            release.archive_at = data.archive_at;
          }
          return release;
        });
      }
      this.setDataFromSocket(null);
    },
    loadData(lastId, appID) {
      this.errorMessage = null;
      if (!this.loader && !this.infoError) {
        if (lastId) {
          this.previousDataLoader = true;
        } else {
          if (this.isLoaded) return;
          this.isLoaded = true;
        }
        this.$store
          .dispatch(RELEASES, {
            app_id: appID || this.app_id,
            last_id: lastId || "",
          })
          .then(() => {
            const latestReleases = this.latest_releases.map((release) => {
              if (release.id === this.getReleaseImage.androidId) {
                release.image = this.getReleaseImage.androidIcon;
              }
              if (release.id === this.getReleaseImage.iosId) {
                release.image = this.getReleaseImage.iosIcon;
              }
              return release;
            });
            this.setLatestRelease(latestReleases);
            this.previousDataLoader = false;
            this.isLoaded = false;
            this.removeReleaseImage();
          })
          .catch((err) => {
            this.previousDataLoader = false;
            this.isLoaded = false;
            this.removeReleaseImage();
            if (err) {
              this.errorMessage = err.message || null;
            }
          });
      }
    },
    showInfo(event) {
      this.message = event;
      this.alert = true;
    },
    replaceDefaultReleaseImage(e) {
      e.target.src = "/media/defaults/icon-50.png";
    },
    onArchivedClicked(release, fromList) {
      this.selectedRelease = release;
      if (fromList) {
        this.fromList = true;
      }
      this.archiveModal = true;
    },
    onDeleteClick(release) {
      this.selectedRelease = release;
      if (release.status !== 2) {
        this.modal = true;
      } else {
        this.onConfirm();
      }
    },
    openInstallList(details) {
      this.selectedRelease = details;
      this.showReleaseInstallList = true;
    },
    openReleaseFeedback(details) {
      this.selectedRelease = details;
      this.showReleaseFeedback = true;
    },
    onDownloadClick(release) {
      Vue.prototype.$analytics.logEvent("download_release_inside");
      window.analytics.track("Downloaded release", {
        platform: release.platform,
        app_id: this.app_id,
        release_id: release.id,
      });

      if (release.platform == "ios") {
        window.open(
          release.url.replace(new RegExp("plist"), "ipa") +
            "?user_id=" +
            this.user.id,
          "_self"
        );
      } else {
        window.open(release.url + "?user_id=" + this.user.id, "_self");
      }
    },
    onCancel() {
      this.modal = false;
      this.fromList = false;
      Vue.prototype.$analytics.logEvent("cancel_delete_release");
      this.selectedRelease = null;
    },
    onCancelArchive() {
      this.archiveModal = false;
      Vue.prototype.$analytics.logEvent("cancel_archive_release");
      this.selectedRelease = null;
    },
    onEditClick(release) {
      this.selectedRelease = release;
      this.viewEditRelease = true;
    },
    openReleaseInfo(release) {
      this.selectedRelease = release;
      this.viewRleaseInfo = true;
    },
    onConfirmArchive() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(ARCHIVE_RELEASE, {
          app_id: this.app_id,
          release_id: this.selectedRelease.id,
        })
        .then((response) => {
          this.notifyUserMessage(
            response.message || "Release archived successfully"
          );
          this.setAndroidLatestInstallData(null);
          this.setIosLatestInstallData(null);
          this.setLatestAndroidFeedback(null);
          this.setLatestIosFeedback(null);
          if (this.fromList) {
            this.changeReleaseToArchive(this.selectedRelease.id);
          } else {
            this.loadData();
          }
          const size =
            this.selectedRelease && this.selectedRelease.info
              ? this.selectedRelease.info.size
              : 0;
          this.removeReleaseInfo({
            size: size,
          });
          // this.removeAppStats({
          //   externalStorage:
          //     this.info &&
          //     this.info.storage &&
          //     this.info.storage.name !== "TestApp.io"
          //       ? size
          //       : null,
          //   storage:
          //     this.info &&
          //     this.info.storage &&
          //     this.info.storage.name === "TestApp.io"
          //       ? size
          //       : null,
          // });
          this.archiveModal = false;
          this.loading = false;
          this.fromList = false;
          Vue.prototype.$analytics.logEvent("archive_release");
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Something went wrong");
          this.loading = false;
          this.fromList = false;
          Vue.prototype.$analytics.logEvent("archive_release_error");
        });
    },
    async onConfirm() {
      if (this.loading) return;
      this.loading = true;
      try {
        const response = await this.$store.dispatch(DELETE_RELEASE, {
          app_id: this.app_id,
          release_id: this.selectedRelease.id,
        });
        if (!this.releases.length && this.page > 1) {
          this.page = this.page - 1;
        }
        this.removeDeletedRelease(this.selectedRelease.id);
        this.notifyUserMessage(
          response.message || `Release successfully deleted`
        );

        this.removeReleaseInfo({
          count: 1,
          size:
            this.selectedRelease.status === 2
              ? this.selectedRelease.info.size
              : null,
        });
        this.setPreviousRelease(
          this.totalReleaseData.slice((this.page - 1) * 10, this.page * 10)
        );
        Vue.prototype.$analytics.logEvent("delete_release");
      } catch (err) {
        this.notifyErrorMessage(err.message || "Delete release failed");
        Vue.prototype.$analytics.logEvent("delete_release_error");
      }
      this.loading = false;
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
.release-main {
  padding: 0 0;
  .release-card {
    padding: 0 2.25rem;
    .card-header {
      border: 0;
      padding: 0;
    }
  }
}
.htw-40 {
  height: 40px;
  @media (max-width: 800px) {
    height: auto;
  }
}
.htw-120 {
  height: 120px;
  @media (max-width: 800px) {
    height: auto;
  }
}
.detail-release-roew {
  .rel-card {
    margin: 12.5px 0;
  }
}
.release-table tbody tr {
  cursor: pointer !important;
}
</style>
