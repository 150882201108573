<template>
  <div class="d-flex flex-column flex-root h-100">
    <div class="d-flex flex-column h-100">
      <div>
        <div class="mb-12">ID: {{ get(release, "id", "") }}</div>
        <h1 class="text-center mt-2 display-1">
          v{{ get(release, "info.version", "") }}
        </h1>
      </div>
      <div class="d-flex mb-1">
        <div class="row no-gutters">
          <h1 class="col-12 text-center mt-4 mb-6">
            {{ get(release, "info.name", "") }}
          </h1>
          <div class="col-12 mb-6 mx-auto text-center">
            <v-icon
              color="primary"
              v-text="
                release.platform === 'android' ? 'mdi-android' : 'mdi-apple'
              "
            ></v-icon>
          </div>
          <!-- <div class="col-12 mt-2">
            <div class="row">
              <div class="col-6 text-center">
                <h1>
                  {{ totalMembers }}
                  / {{ get(app, "meta.total_members", 0) }}
                </h1>
                <div class="bold-text text-muted font-size-h2">
                  Members
                </div>
              </div>
              <div class="col-6 text-center">
                <h1>
                  {{ get(release, "meta.total_installs", 0) }}
                </h1>
                <div class="bold-text text-muted font-size-h2">
                  Install
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-12">
            <v-row no-gutters>
              <v-col cols="12" sm="6" class="text-center mb-4">
                <div class="font-size-h2 text-muted px-4 py-1">Package</div>
                <div class="font-size-h4">
                  {{ get(release, "info.package", "") }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="text-center mb-4">
                <div class="font-size-h2 text-muted px-4 py-1">
                  Version Code
                </div>
                <div class="font-size-h4">
                  {{ get(release, "info.version_code", "") }}
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="col-12">
            <v-row no-gutters>
              <v-col cols="12" sm="6" class="text-center mb-4">
                <div class="text-muted px-4 py-1 font-size-h2">File Name</div>
                <div class="font-size-h4">
                  <!-- for more information about this component read the docs -->
                  {{ get(release, "info.uploaded_file_name", "") }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="text-center mb-4">
                <div class="text-muted px-4 py-1 font-size-h2">Developer</div>
                <div class="font-size-h4">
                  {{ get(release, "user.name", "") }}
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col v-if="release.status !== 2" class="text-center pt-0" cols="12">
          <div class="font-size-h2">Install this release</div>
          <div>
            <div v-if="release.status !== 1" class="loadingOverlay">
              <v-progress-circular
                class="mt-12"
                color="primary"
                width="6"
                size="80"
                indeterminate
              ></v-progress-circular>
            </div>
            <template>
              <div class="d-flex justify-center align-center">
                <qrcode :value="installLink" :options="{ width: 200 }" />
              </div>
              <div class="font-size-h4 text-center mx-7 mb-2 mt-2">
                Scan this QR code from your device or copy the link
              </div>
            </template>
          </div>
          <div class="mt-4 text-center">
            <v-chip class="radius-10 darkGrey--text">
              <div v-if="release.status !== 1">
                {{ $options.STATUS[release.status].title }}
              </div>
              <MaxText v-else :text="installLink" :max="34" />
            </v-chip>
            <v-btn
              :disabled="release.status !== 1"
              @click="copy(installLink)"
              icon
            >
              <v-icon class="ml-2" v-text="'mdi-content-copy'" />
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex flex-column mt-3">
        <v-divider />
        <!-- notes -->
        <div class="py-3">
          <h2 class="mb-8 text-center">Notes</h2>
          <div style="white-space: pre-line" v-if="release.note">
            <div v-if="release && release.note.length > 175">
              {{ readMore ? release.note.substring(0, 175) : release.note }}
              <span
                style="cursor: pointer"
                class="primary--text font-weight-bold"
                @click="readMore = !readMore"
                >{{ readMore ? "... Read More" : "Read Less" }}</span
              >
            </div>
            <div v-else>
              {{ release.note }}
            </div>
          </div>
          <div
            v-else
            class="d-flex flex-center text-center text-muted min-h-200px"
          >
            <div class="no-data-found mx-auto">
              <inline-svg
                class="mt-6 svg-icon empty_icon"
                src="/media/placeholders/all_done.svg"
              />
              <p class="mt-2 empty-title bold-text text-dark text-center">
                No release notes
              </p>
            </div>
          </div>
          <!--  -->
        </div>
        <!-- notes -->

        <!-- <div class="bold-text overflow-auto max-h-100px">
          {{ get(release, "note", "") }}
        </div> -->
        <!-- <hr class="line-1" /> -->
      </div>
      <!-- <div class="pb-7">
        <h2 class="mt-2 mb-7 text-center">Members</h2>
        <div>
          <div>
            <div v-if="loading">
              <ContentLoader />
            </div>
            <div v-else>
              <div v-if="!filteredMember.length" class="no-data-found">
                <inline-svg
                  class="svg-icon empty_icon"
                  src="/media/placeholders/no_devices.svg"
                />
                <p class="text-dark text-center">No installs</p>
              </div>

              <div
                class="d-flex align-center justify-space-between member-panel cursor-pointer"
                v-for="(memberData, j) of filteredMember"
                v-bind:key="j"
                @click="() => openDeviceInfo(memberData)"
              >
                <img src="/media/defaults/icon-120.png" />
                <div class="mx-4">{{ get(memberData, "member.name", "") }}</div>
                <div class="text-muted bold-text mx-1">
                  Installed
                </div>
                <div class="text-muted bold-text mx-1">
                  {{ getInstalledDevices(get(memberData, "devices", {})) }}
                </div>
                <div class="text-muted bold-text mx-1">
                  {{
                    getInstallTime(
                      get(memberData, "devices.installed[0].created_at", "")
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <SidePanel v-model="showDeviceInfo" width="700">
      <!--  -->
      <DeviceInfoPanel
        v-if="showDeviceInfo"
        @close="showDeviceInfo = false"
        :devices="devices"
        release
        :member="member"
        :app="get(app, 'name', '')"
      />
      <!--  -->
    </SidePanel>
  </div>
</template>

<script>
import { getInstallTime, get } from "@/core/services/helper.service";
import DeviceInfoPanel from "@/view/components/Team/Members/DeviceInfoPanel.vue";

import { GET_RELEASE_MEMBER } from "@/store/releases/releases.module.js";
import { GET_MEMBER_DEVICE_LIST } from "@/store/team/team.module";
import { mapGetters } from "vuex";
export default {
  name: "release-info",
  props: ["app_id", "release", "app", "onClose"],
  components: {
    DeviceInfoPanel,
  },
  watch: {
    release: {
      handler: function () {
        this.getMemberList();
      },
    },
  },
  STATUS: {
    1: { title: "Active", color: "green" },
    2: { title: "Failed", color: "red" },
    3: { title: "Archived", color: "grey" },
    4: { title: "Uploading", color: "orange" },
    5: { title: "Processing", color: "orange" },
  },
  data() {
    return {
      get,
      releaseInfo: null,
      getInstallTime,
      member: "",
      readMore: true,
      devices: [],
      search: "",
      loading: false,
      totalMembers: 0,
      showDeviceInfo: false,
      memberList: [],
    };
  },
  created() {
    window.analytics.track("Opened install release info", {
      platform: this.release.platform,
      app_id: this.$route.params.app_id,
      release_id: this.release.id,
    });
  },
  computed: {
    ...mapGetters({
      getTeamId: "getTeamId",
    }),
    filteredMember() {
      return this.memberList.filter((member) =>
        member.member.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    installLink() {
      return `${location.origin}/install/${this.$route.params.app_id}/${this.release.id}${this.app.public ? '' : '/?team_id=' + this.getTeamId}`;
    },
  },
  mounted() {
    // this.getMemberList();
  },
  methods: {
    // TODO@OMAR remove result zero
    getMemberList() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_RELEASE_MEMBER, {
          app_id: this.app_id,
          release_id: this.release.id,
        })
        .then((response) => {
          this.totalMembers = response.results[0].meta.total;
          this.memberList = response.results[0].data;
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
    getInstalledDevices(devices) {
      const totalDevices = get(devices, "total", 0);
      const installed = get(devices, "installed", []);

      if (installed.length == totalDevices) {
        return "All Devices";
      } else {
        return `${installed.length} / ${totalDevices} Devices`;
      }
    },
    getDeviceList() {
      this.$store
        .dispatch(GET_MEMBER_DEVICE_LIST)
        .then((response) => {
          this.devices = response.data;
          this.showDeviceInfo = true;
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    },
    openDeviceInfo(data) {
      this.member = get(data, "member.name", "");
      this.devices = data.devices.installed;
      this.showDeviceInfo = true;
    },
  },
};
</script>

<style lang="scss">
.empty_icon {
  width: 100px;
}
.member-search {
  border: 1px solid #b5b5c3;
  background-color: #fff !important;
}
.loadingOverlay {
  height: 200px;
  width: 200px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 5;
  z-index: 9;
  position: absolute;
  left: 200px;
}
.member-panel {
  padding: 10px;
  border: 1px solid rgba(57, 107, 219, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
  img {
    height: 40px;
    width: 40px;
  }
}
</style>
