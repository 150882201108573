<template>
  <v-card flat>
    <v-row>
      <v-col cols="12">
        <div class="black--text mt-4 font-size-h3">Move release</div>
      </v-col>
      <v-col class="bodyFont pb-0" cols="12">
        <ul class="dark-grey-text">
          <li>
            Platform: {{ release.platform === "android" ? "Android" : "iOS" }}
          </li>
          <li>
            Version:
            <MaxText
              :text="`v${release.info.version} (${release.info.version_code})`"
              max="40"
            />
          </li>
        </ul>
      </v-col>
      <v-col class="py-0" cols="12">
        <AppAutoComplete
          class="mb-2"
          v-model="app"
          @openApp="$emit('openApp')"
          :hide-app-id="$route.params.app_id"
          :current-app="currentApp"
          @input="app = $event"
          ref="app-autocomplete"
        />
        <div v-if="isDifferentStorage">
          <span class="error--text"
            >Both apps should have the same storage type
          </span>
        </div>
      </v-col>
      <v-col class="pb-0" cols="12">
        <v-checkbox
          hide-details
          v-model="formValues.mark_latest"
          label="Mark as a latest release in the new app"
        ></v-checkbox>
      </v-col>
      <v-col class="py-0" cols="12">
        <v-checkbox
          hide-details
          v-model="formValues.notify"
          label="Notify new app members about this move"
        ></v-checkbox>
      </v-col>
      <v-col class="text-right" cols="12">
        <v-btn
          @click="$emit('close')"
          class="text-transform-none mx-3"
          color="primary"
          :disabled="loading"
          text
          >Cancel</v-btn
        >
        <v-btn
          @click="submit"
          class="text-transform-none"
          :disabled="!app.id || isDifferentStorage"
          :loading="loading"
          color="primary"
          depressed
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import AppAutoComplete from "@/view/components/Common/AppAutoComplete.vue";
import { MOVE_RELEASE } from "@/store/releases/releases.module.js";
export default {
  components: { AppAutoComplete },
  props: {
    release: {
      type: Object,
      default() {
        return "";
      },
    },
    currentApp: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    isDifferentStorage() {
      return (
        this.currentApp.storage &&
        this.app.storage &&
        this.currentApp.storage.name &&
        this.app.storage.name &&
        this.currentApp.storage.name !== this.app.storage.name
      );
    },
  },
  created() {
    this.app = {};
  },
  data() {
    return {
      formValues: {
        mark_latest: false,
        notify: false,
        app_id: "",
      },
      app: {},
      loading: false,
    };
  },
  watch: {
    app(val) {
      if (val.id === this.$route.params.app_id) {
        this.app = {};
      }
      if (val && val.storage && !val.storage.name) {
        val.storage.name = "TestApp.io";
      }
    },
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.loading = true;
      if (this.app && this.app.id) {
        this.formValues.app_id = this.app.id;
        this.formValues.currentAppId = this.$route.params.app_id;
        this.formValues.release_id = this.release.id;
        this.$store
          .dispatch(MOVE_RELEASE, this.formValues)
          .then((response) => {
            this.notifyUserMessage(
              `${response.message}. <br> Click <a href="/app/${this.app.id}/releases">here</a> go to <b>${this.app.name}</b> app`
            );
            this.$emit("close");
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
  },
};
</script>

<style></style>
