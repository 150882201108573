<template>
  <v-card class="mx-auto mt-10 card primary new-release-card">
    <v-row no-gutters>
      <v-col
        class="ml-2 d-flex justify-center align-center flex-column add-area"
      >
        <div v-if="canManageRelease">
          <h1 class="text-center mb-3">Create your first release</h1>
          <span
            @click="$emit('openNewRelease')"
            class="mx-auto text-capitalize new-release-btn"
            >Let’s start</span
          >
        </div>
        <div v-else>
          <h1 class="text-center mb-3">No latest releases</h1>
        </div>
      </v-col>
      <v-col class="shapes">
        <!-- svg -->
        <img src="/media/svg/bg-image-new-release.svg" alt="creat release" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    appInfo: {
      type: [Object, String],
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      click: false,
    };
  },
  methods: {
    closeIt() {
      this.click = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-release-card {
  overflow: hidden;
  width: 430px;
  height: 182px;
  border-radius: 12px !important;
  border: none;
  display: flex;
  * {
    font-size: 20px;
    font-weight: 600;
  }

  .add-area {
    color: white;
    text-align: center;
    letter-spacing: 1px;
    line-height: 27px;
    .new-release-btn {
      cursor: pointer;
      display: flex;
      align-content: center;
      justify-content: center;
      font-size: 18px !important;
      height: 34px;
      width: 124px;
      border-radius: 6px;
      padding-top: 3px;

      background-color: #36a4ff;
      text-align: center;
    }
  }
  .shapes {
    img {
      width: calc(100% + 50px);
      height: calc(100% + 3px);
      transform: translateX(-50px);
    }
  }
}
</style>
