<template>
  <div>
    <div class="mt-1">
      <!-- add prop both if you want for both platforms -->

      <div class="dashboard_main">
        <Modal
          style="z-index: 99999"
          v-model="installModal"
          :width="660"
          :loading="loading"
          :fullscreen="$vuetify.breakpoint.smAndDown"
        >
          <template #message>
            <InstallModal
              v-if="installModal"
              :platform="installPlatform"
              :both="forBoth"
              :app-info="{
                releaseId: currentInstallReleaseId,
                url: releaseUrl,
                ...currentInstallReleaseVersion,
                ...info,
              }"
              :from-app-info="fromAppInfo"
            />
          </template>
        </Modal>
        <Modal
          v-model="instructionModal"
          :width="660"
          :fullscreen="$vuetify.breakpoint.smAndDown"
        >
          <template #message>
            <install-intruction-modal
              v-if="instructionModal"
              :release="{
                releaseId: currentInstallReleaseId,
                ...currentInstallReleaseVersion,
                ...info,
              }"
            ></install-intruction-modal>
          </template>
        </Modal>
        <v-row v-if="errorMessages" class="mt-xs-6">
          <v-col cols="12" align-self="center">
            <noData
              :firstText="errorMessages"
              permission-issues
              with-button
              class="mt-n3"
            />
          </v-col>
        </v-row>
        <Releases
          v-else
          @install="openInstall"
          @showInstruction="openInstructionModal"
          :app_id="app_id"
          :app-info="info"
          @info-error="errorMessages = $event"
          :loader="loading"
          ref="releases"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, get } from "@/core/services/helper.service";
import { mapGetters, mapState } from "vuex";
import Releases from "@/view/components/Releases/Releases.vue";
import InstallModal from "@/view/components/App/InstallModal.vue";
import StorageService from "@/core/services/localstorage.service";
import InstallIntructionModal from "@/view/components/Releases/InstallIntructionModal";

export default {
  name: "app",
  data() {
    return {
      get,
      readMore: false,
      fromAppInfo: false,
      forBoth: false,
      app_id: "",
      tab: 0,
      loading: false,
      errorMessages: null,
      viewEditApp: false,
      releaseUrl: "",
      leave: null,
      infoLoaded: false,
      loadingConfirm: false,
      instructionModal: false,
      modal: null,
      installModal: false,
      installPlatform: null,
      currentInstallReleaseId: "",
      currentInstallReleaseVersion: "",
    };
  },
  components: {
    InstallIntructionModal,
    Releases,
    InstallModal,
  },
  computed: {
    ...mapState({
      releases: (state) => state.app.releases,
      info: (state) => state.app.info,
    }),
    ...mapGetters({
      appDetail: "getAppInfo",
    }),
    noInfo() {
      return isEmpty(this.info) ? true : false;
    },
  },
  watch: {
    "$route.query.install": {
      handler: function (v) {
        if (v && this.$route.query.install) {
          this.showInstallInstruction();
        } else {
          this.instructionModal = false;
        }
      },
      deep: true,
      immediate: true,
    },
    installModal(v, oldV) {
      if (!v && v !== oldV) {
        this.fromAppInfo = false;
        if (this.$route.query.install) {
          this.$router.replace(this.$route.path);
        }
      }
    },
  },
  created() {},
  methods: {
    openInstall(release) {
      this.fromAppInfo = false;
      if (release) {
        this.installPlatform = release.platform;
        this.currentInstallReleaseId = release.id;
        this.releaseUrl = release.url;
        this.currentInstallReleaseVersion = {
          version: release.info.version,
          version_code: release.info.version_code,
        };
        this.forBoth = false;
      } else {
        this.installPlatform = "";
        this.currentInstallReleaseId = "";
        this.currentInstallReleaseVersion = "";
        this.forBoth = true;
        this.fromAppInfo = true;
      }
      this.installModal = !this.installModal;
    },
    showInstallInstruction() {
      const popup = StorageService.getItem("popup");
      if (!popup || !popup.install_instruction) {
        StorageService.setItem("popup", { install_instruction: true });
        this.showInstructionModal = true;
      } else if (popup && popup.install_instruction === "false") {
        this.instructionModal = true;
      }
    },
    openInstructionModal() {
      this.showInstallInstruction();
    },
  },
};
</script>
