<template>
  <div>
    <div class="row">
      <div class="col-2"></div>
      <h3 class="col-8 m-0 ff-open-sans-bold text-center font-size-h1">
        v{{ get(release, "info.version", "") }}
      </h3>
    </div>
    <div>
      <v-card class="transparent" tile flat :disabled="btnLoader">
        <v-form
          ref="ios"
          class="form mt-8"
          @submit.prevent="() => submitRelease()"
        >
          <!--  -->
          <template>
            <v-textarea
              v-model="form.note"
              :counter="600"
              v-validate="'max:600'"
              v-bind="veeValidate('Description', 'Description')"
              label="Release notes"
              placeholder="What to expect or test in this release?"
              auto-grow
              filled
            ></v-textarea>
          </template>
          <v-btn
            :loading="btnLoader"
            :disabled="btnLoader || !form.note || errors.any()"
            class="main primary wide large mt-13 mx-auto"
            ref="submit_btn"
            type="submit"
            >Submit</v-btn
          >
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { get } from "@/core/services/helper.service";
import { UPDATE_RELEASE } from "@/store/releases/releases.module.js";
import veeValidate from "@/mixins/veeValidate";

export default {
  name: "create-release",
  props: ["app_id", "release", "onClose"],
  mixins: [veeValidate],
  components: {},
  data() {
    return {
      show: null,
      btnLoader: false,
      allValid: false,
      form: {
        note: ""
      },
      get
    };
  },
  watch: {
    release: function(value) {
      this.form.note = get(value, "note", "");
    }
  },
  created() {
    this.form.note = this.release.note;
    Vue.prototype.$analytics.logEvent("update_release_start");
  },
  methods: {
    resetForm() {
      this.form = {
        note: ""
      };
    },
    async submitRelease() {
      if (this.errors.any() || this.btnLoader) {
        return false;
      }
      this.btnLoader = true;

      try {
        const response = await this.$store.dispatch(UPDATE_RELEASE, {
          app_id: this.app_id,
          release_id: this.release.id,
          note: this.form.note
        });
        this.onClose && this.onClose();
        this.notifyUserMessage(
          response.message || `Release updated successfully`
        );
        Vue.prototype.$analytics.logEvent("update_release");
      } catch (err) {
        this.notifyErrorMessage(err.message || `Update release failed`);
        Vue.prototype.$analytics.logEvent("update_release_error");
      }
      this.btnLoader = false;
    }
  }
};
</script>
