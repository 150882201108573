<template>
  <v-card
    :class="
      activeInfo.status === 2 ? 'border-red-light' : 'border-primary-light'
    "
    flat
    class="release-card cursor-pointer radius-10"
  >
    <v-row class="pa-3" align="center">
      <v-col cols="3" md="1" class="pr-0">
        <div class="toogleContainer">
          <span
            class="cursor-pointer pt-1"
            @click="setActiveInfo('android')"
            :class="
              activeInfo === 'android' ? 'toogleButtonAndroid' : 'mb-1 pl-2'
            "
          >
            <v-icon
              :color="activeInfo === 'android' ? 'white' : ''"
              class="pr-2 pt-1"
              size="20"
              >mdi-android</v-icon
            >
          </span>
          <span
            class="cursor-pointer pb-1 pl-1"
            @click="setActiveInfo('ios')"
            :color="activeInfo === 'ios' ? 'white' : ''"
            :class="activeInfo === 'ios' ? 'toogleButtonIos' : 'pr-1 pb-2'"
          >
            <v-icon :color="activeInfo === 'ios' ? 'white' : ''">
              mdi-apple
            </v-icon>
          </span>
        </div>
      </v-col>
      <template v-if="!isEmpty(this.getActiveInfo)">
        <v-col class="pl-0" cols="9" md="3">
          <v-row align="center">
            <v-col cols="auto">
              <div
                :class="{ 'opacity-5': [4, 5].includes(getActiveInfo.status) }"
              >
                <img
                  v-if="getActiveInfo.icon || getActiveInfo.image"
                  :src="getActiveInfo.icon || getActiveInfo.image"
                  height="58"
                  contain
                />
                <img
                  v-else
                  src="/media/defaults/icon-120.png"
                  contain
                  height="58"
                />
              </div>
            </v-col>
            <v-col class="pl-0">
              <div class="mb-2">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ get(getActiveInfo, "info.name", "-") }}
                    </span>
                  </template>
                  <span>{{ get(getActiveInfo, "info.package", "") }}</span>
                </v-tooltip>
              </div>
              <div>
                <MaxText
                  :text="`v${get(getActiveInfo, 'info.version', '-')} (${get(
                    getActiveInfo,
                    'info.version_code',
                    ''
                  )})`"
                  :max="20"
                ></MaxText>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" md="3">
          <div
            @click.stop="tab = 0"
            class="mb-2 cursor-pointer"
            v-if="canManageApp"
          >
            <v-icon small v-text="'mdi-cellphone-arrow-down'"> </v-icon>
            {{ totalDownloads }}
            Installs
          </div>
          <div>By {{ get(getActiveInfo, "user.name", "") }}</div>
        </v-col>
        <v-col class="pr-0" cols="6" md="3">
          <div
            @click.stop="tab = 1"
            class="mb-2 cursor-pointer"
            v-if="canManageApp"
          >
            <v-icon small v-text="'mdi-message-outline'"> </v-icon>
            {{ get(getActiveInfo, "meta.total_feedback", "0") }}
            Feedback
          </div>

          <div>
            Released:
            <vue-hoverable-date
              v-if="getActiveInfo"
              :date="getActiveInfo.created_at"
            ></vue-hoverable-date>
            <span v-else> - </span>
          </div>
        </v-col>
        <v-col
          :class="$vuetify.breakpoint.mdAndUp ? 'pl-0' : ''"
          cols="6"
          md="2"
        >
          <div class="mb-2">
            Size: {{ formatBytes(get(getActiveInfo, "info.size", 0)) }}
          </div>
          <div>
            Expire:
            <span v-if="getActiveInfo && getActiveInfo.archive_at">
              <vue-hoverable-date
                v-if="getActiveInfo.archive_at"
                :date="getActiveInfo.archive_at"
              ></vue-hoverable-date>
            </span>
            <span v-else>
              <vue-tooltip text="No expiry date" displayText="-"></vue-tooltip>
            </span>
          </div>
          <div v-if="getActiveInfo">
            <v-tooltip
              bottom
              v-if="[4, 5].includes(getActiveInfo.status)"
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="font-weight-bold title"
                  color="primary"
                  absolute
                  v-bind="attrs"
                  v-on="on"
                  top
                  right
                  style="z-index: 3"
                >
                  ?
                </v-btn>
              </template>
              <span>
                <div class="mb-1">
                  We are currently processing the release to be available for
                  install.
                </div>
                <div class="mb-1">
                  The status changes are in real-time no need to refresh your
                  page.
                </div>
                <div class="my-2">
                  Estimated for this release:
                  {{ getEstimateTime(get(getActiveInfo, "info.size", 0)) }}
                </div>
              </span>
            </v-tooltip>
            <action-dropdown color="release-title" absolute v-else>
              <template v-slot:list>
                <!-- item in the drop down -->
                <v-list-item
                  v-if="canManageRelease && getActiveInfo.status === 1"
                  @click="$emit('onEdit', getActiveInfo)"
                >
                  <v-icon small>edit</v-icon>edit note
                </v-list-item>

                <!-- item in the drop down -->
                <!--                <v-list-item-->
                <!--                  v-if="getActiveInfo.status === 1"-->
                <!--                  @click="$emit('install')"-->
                <!--                  ><v-icon small>mdi-cellphone-iphone</v-icon>install-->
                <!--                </v-list-item>-->
                <v-list-item
                  v-if="canManageRelease && getActiveInfo.status === 1"
                  @click="$emit('move-release', getActiveInfo)"
                  ><v-icon small>mdi-cursor-move</v-icon>Move
                  <!-- <v-icon
                    v-if="!isPaidUser"
                    small
                    left
                    class="mx-2"
                    v-text="'mdi-lock'"
                  ></v-icon> -->
                </v-list-item>

                <!-- item in the drop down -->
                <v-list-item
                  v-if="canManageRelease && getActiveInfo.status === 1"
                  @click="$emit('onArchive', getActiveInfo)"
                  ><v-icon small>archive</v-icon>Archive
                </v-list-item>
                <v-list-item
                  v-if="getActiveInfo.status === 1"
                  @click="$emit('onDownload', getActiveInfo)"
                  ><v-icon small>mdi-cloud-download</v-icon>Download file
                </v-list-item>

                <!-- item in the drop down -->
                <v-list-item
                  v-if="
                    canManageRelease && [2, 3].includes(getActiveInfo.status)
                  "
                  @click="$emit('onDelete', getActiveInfo)"
                  ><v-icon small>cancel</v-icon>Delete
                </v-list-item>
              </template>
            </action-dropdown>
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="10" class="">
          <div class="bodyFont text-center mb-3">
            No <b>{{ activeInfo == "ios" ? "iOS" : "Android" }}</b> release
            found <br /><span v-if="canManageApp"
              >You can create one from the "New Release" button</span
            >
          </div>
        </v-col>
      </template>
    </v-row>
    <v-divider v-if="!isEmpty(getActiveInfo)" class="pt-0"></v-divider>
    <v-card-text v-if="!isEmpty(getActiveInfo)">
      <v-row v-if="getActiveInfo">
        <v-col v-if="[4, 5].includes(getActiveInfo.status)" cols="12">
          <div class="black--text my-3 text-center">
            <div class="mb-3">
              <v-progress-circular
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <template v-if="getActiveInfo.status == 4">Uploading...</template>
            <template v-if="getActiveInfo.status == 5">Processing...</template>
          </div>
        </v-col>
        <template v-else>
          <v-col cols="12">
            <v-tabs v-model="tab">
              <v-tab class="mr-2"> Installs </v-tab>
              <v-tab class="mr-2"> Feedback </v-tab>
              <v-tab class="mr-2"> Notes </v-tab>
              <v-tab-item>
                <install-list
                  :app_id="appInfo.id"
                  from-release-page
                  :app="appInfo"
                  :key="activeInfo"
                  from-latest
                  :platform="activeInfo"
                  :release="getActiveInfo"
                ></install-list>
              </v-tab-item>
              <v-tab-item>
                <release-feedback
                  :app_id="appInfo.id"
                  from-release-page
                  :app="appInfo"
                  :key="activeInfo"
                  from-latest
                  :platform="activeInfo"
                  :release="getActiveInfo"
                ></release-feedback>
              </v-tab-item>
              <v-tab-item>
                <div v-if="getActiveInfo.note">
                  <div style="white-space: pre-line" class="mt-2">
                    {{ getActiveInfo.note || "-" }}
                  </div>
                </div>
                <div v-else>
                  <no-data
                    first-text=" No releases notes"
                    second-text="Release notes are useful to help your testers to
                      understand what to expect and test"
                  ></no-data>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getReleaseTime,
  formatBytes,
  get,
  isEmpty,
} from "@/core/services/helper.service";
// import LineCharts from "@/view/components/Common/Charts/LineCharts.vue";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import InstallList from "@/view/components/App/Dashboard/InstallList.vue";
import ReleaseFeedback from "@/view/components/App/ReleaseFeedback";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["releases", "app"],
  components: { ActionDropdown, InstallList, ReleaseFeedback },
  data() {
    return {
      getReleaseTime,
      formatBytes,
      get,
      isEmpty,
      activeInfo: "android",
      tab: 0,
      downloadInstallChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Install",
            data: [0, 0, 3, 0, 0, 0, 5, 0, 0, 6, 0, 0],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
          {
            label: "Views",
            data: [0, 1, 0, 0, 3, 0, 0, 7, 0, 0, 4, 0],
            fill: false,
            borderColor: "rgb(155, 213, 121)",
            tension: 0.1,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      getLatestReleases: "getLatestReleases",
      appInfo: "getAppInfo",
    }),
    getAndroidInfo() {
      return (
        this.getLatestReleases.find(
          (release) => release.platform === "android"
        ) || {}
      );
    },
    getIosInfo() {
      return (
        this.getLatestReleases.find((release) => release.platform === "ios") ||
        {}
      );
    },
    getActiveInfo() {
      return this.activeInfo === "android"
        ? this.getAndroidInfo
        : this.getIosInfo;
    },
    totalDownloads() {
      if (!isEmpty(this.getActiveInfo)) {
        if (
          !this.getActiveInfo.meta.total_downloads &&
          !this.getActiveInfo.meta.total_installs
        )
          return "0";
        else
          return this.getActiveInfo && this.getActiveInfo.meta
            ? this.getActiveInfo.meta.total_downloads +
                this.getActiveInfo.meta.total_installs
            : 0;
      } else {
        return "-";
      }
    },
  },
  watch: {
    getAndroidInfo(val) {
      if (val && val.status && val.status !== 1) {
        this.activeInfo = "android";
        this.setAndroidLatestInstallData(null);
        this.setLatestAndroidFeedback(null);
      }
    },
    getIosInfo(val) {
      if (val && val.status && val.status !== 1) {
        this.activeInfo = "ios";
        this.setIosLatestInstallData(null);
        this.setLatestIosFeedback(null);
      }
    },
  },
  created() {
    if (isEmpty(this.getAndroidInfo) && !isEmpty(this.getIosInfo)) {
      this.activeInfo = "ios";
    }
  },
  methods: {
    ...mapMutations({
      setAndroidLatestInstallData: "setAndroidLatestInstallData",
      setIosLatestInstallData: "setIosLatestInstallData",
      setLatestAndroidFeedback: "setLatestAndroidFeedback",
      setLatestIosFeedback: "setLatestIosFeedback",
    }),
    getEstimateTime(size) {
      if (0 < size < 30000) {
        return "up to 10 minutes";
      } else if (30000 < size < 50000) {
        return "up to 20 minutes";
      } else if (50000 < size < 100000) {
        return "up to 30 minutes";
      } else if (100000 < size < 500000) {
        return "up to 40 minutes";
      } else if (500000 < size < 700000) {
        return "up to 60 minutes";
      } else if (700000 < size < 1000000) {
        return "up to 60 minutes";
      }
    },
    setActiveInfo(platform) {
      if (platform == "android") {
        this.activeInfo = "android";
      } else {
        this.activeInfo = "ios";
      }
    },
    getInstalls() {
      return (
        get(this.release, "meta.total_members", 0) /
        get(this.app, "meta.total_members", 0)
      );
    },
  },
};
</script>

<style scoped>
.pinding-progress {
  position: absolute;
  z-index: 2;
}
.toogleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 34px;
  height: 69px;
  border-radius: 40px;
  line-height: 16px;
  background-color: #e8e8e8;
  background-color: #e8e8e8;
}
.toogleButtonIos {
  height: 50%;
  background-color: #6c92bf;
  border-radius: 40px;
  padding-top: 4px;
  padding-right: 4px;
  width: 34px;
}
.toogleButtonAndroid {
  height: 50%;
  background-color: #8ed3a6;
  border-radius: 40px;
  padding-top: 4px;
  padding-left: 7px;
  padding-right: 4px;
  width: 34px;
}
</style>
