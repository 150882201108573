<template>
  <v-row class="ma-0 pa-3">
    <v-col cols="12" class="text-h5 text-center">
      <v-icon size="40" color="success"> mdi-bookmark-check </v-icon>Release
      successfully created
    </v-col>
    <v-col class="title text-muted" cols="12">
      We are currently uploading and processing the release to make ready for
      install for everyone 🎉
    </v-col>
    <v-col class="pt-0 title text-muted" cols="12">
      In the meantime, you can prepare the share links in the Share page
    </v-col>
    <!-- <v-col cols="12" md="7" class="mx-auto" v-if="!isExpress">
      <v-img src="/media/instruction/new_release_install_portal.svg"></v-img>
    </v-col> -->
    <v-col v-if="isExpress">
      <v-img src="/media/instruction/new_release_install_express.svg"></v-img>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-center text-center mb-n5 mt-4">
        <v-checkbox
          v-model="checkbox"
          label="Don't show this message after new release."
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    isExpress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkbox: {
      get: function () {
        return localStorage.getItem("popup") &&
          JSON.parse(localStorage.getItem("popup")).install_instruction ===
            "true"
          ? true
          : false;
      },
      set: function (v) {
        localStorage.setItem(
          "popup",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("popup")),
            install_instruction: v.toString(),
          })
        );
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
